
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-toggle::after {
  display: none !important; 
}
.Active{
  background-color: greenyellow;
  color:#000000;
}
.InActive{
  background-color: #d65b58;
  color:#ffffff;
}
.Upcoming{
  background-color: cadetblue;
  color:#ffffff;
}
.Completed{
  background-color: green;
  color:#ffffff;
}

